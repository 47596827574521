import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useStatisticsMonthlyWaterConsumptionV2(
  year,
  month,
  params,
  options,
  key = "getMonthlyWaterConsumptionDataV2",
) {
  const axiosPrivate = useAxiosPrivate();

  const getMonthlyWaterConsumptionDataV2 = async () => {
    const { data } = await axiosPrivate({
      method: "GET",
      url: `WaterConsumption/Biennial/${year}/${month}`,
      params,
    });

    return data;
  };

  return useQuery(
    [key],
    () => getMonthlyWaterConsumptionDataV2(year, month, params),
    {
      ...options,
    },
  );
}

export default useStatisticsMonthlyWaterConsumptionV2;
