import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useStatisticsAnnualWaterConsumptionV2(
  year,
  params,
  options,
  key = "getAnnualWaterConsumption",
) {
  const axiosPrivate = useAxiosPrivate();
  const getAnnualWaterConsumptionV2 = async () => {
    const { data } = await axiosPrivate({
      method: "GET",
      url: `WaterConsumption/${year}`,
      params,
    });

    return data;
  };

  return useQuery([key], () => getAnnualWaterConsumptionV2(year, params), {
    ...options,
  });
}

export default useStatisticsAnnualWaterConsumptionV2;
